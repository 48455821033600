.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 16px;
    background-color: #fff;
}
.welcomeText {
    font-size: 24px;
    margin-bottom: 8px;
}
.description {
    margin-bottom: 64px;
    text-align: center;
}
.buttonRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 72px;
}