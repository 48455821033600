.reportDownload {
    position: absolute;
    bottom: 36px;
    z-index: 6;
    right: 16px;
    min-width: 300px;
    font-size: 12px;
    font-weight: 400;
}

.reportDownloadContainer {
    overflow: auto;
    height: 75%;
    padding-right: 8px;
}
.reportDownloadHeader {
    background-color: #999;
    color: #fff;
    padding: 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.closeReportButton {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555;
    cursor: pointer;
}

.fileContainer {
    padding-top: 16px;
}

.progressBarContainer {
    height: 4px;
        margin-top: 8px;
    background: #d8d8d8;
    position: relative;
    border-radius: 4px;
}

.progressbar {
    composes: progressBarContainer;
    background-color: #1B87E6;
    max-width: 100%;
}
.inProgressFilesTitle {
    color: #545E6B;
}

.topBorder {
    padding: 16px;
    border-top: 4px solid #0D9D5B;
    background: #f5f5f5;
    margin-bottom: 16px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 4px 8px 0px #00000014;
}

.topBorderForIncomplete {
    padding: 16px;
    border-top: 4px solid #1B87E6;
    background: #f5f5f5;
    margin-bottom: 16px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 4px 8px 0px #00000014;
}

.inProgressFilesTitle {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.fileName {
    font-size: 12px;
    font-weight: 400;
    color: #9B9B9B;
}

.date {
    color: #1B87E6;
}

.completedFilesText {
    font-size: 12px;
    font-weight: 400;
    color: #9B9B9B;
}

.doneTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.doneTextRow {
    color: #0D9D5B;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
}

.doneText {
    font-size: 12px;
    font-weight: 500;
    margin-left: 8px;
}
.fileDownloadInQueue {
    font-size: 12px;
    font-weight: 400;
    margin-top: 46px;
}
.topBorderForError {
    padding: 16px;
    border-top: 4px solid #D0021C;
    background: #f5f5f5;
    margin-bottom: 16px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0px 4px 8px 0px #00000014;
}

.errorText {
    color: #D0021C;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.somethingWentWrongText{
    color: #9B9B9B;
}
.errorInfo {
    font-size: 14px;
    margin-right: 8px;
}
.errorInfoRow {
    display: flex;
    align-items: center;
}