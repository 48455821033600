.container {
  width: 100%;
  max-width: 935px;
}

.innerContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 400;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 430px);
  margin: 16px 0;
  padding-right: 16px;
  box-sizing: content-box;
}

.innerContainerForReport {
  max-height: 100%;
  padding-right: 0;
}

.row {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.leftSide {
  display: flex;
  min-width: 200px;
  margin-right: 16px;
  font-size: 14px;
  flex-direction: column;
  width: 50%;
}

.swipeOptionsLabels {
  display: flex;
  font-weight: 500;
}

.rightSide {
  display: flex;
  justify-content: space-between;
  min-width: 125px;
  width: 50%;
  flex-direction: column;
}
.answerRow {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #1b3380;
  align-items: center;
}
.centerIcon {
  height: 16px;
  width: 16px;
  margin: 0 8px;
}
.bar {
  height: 16px;
  border-radius: 4px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .leftSide {
    margin-right: 0px;
    width: 100%;
  }
  .rightSide {
    margin-right: 0px;
    width: 100%;
    margin-top: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
  }
  .row {
    flex-direction: column;
    margin-top: 0;
  }
}
