.container {
  min-width: 390px;
  background-color: #f5f5f5;
  max-width: 890px;
  padding: 8px 16px;
  position: relative;
  display: flex;
  margin: 8px 0;
  width: 100%;
  border-radius: 4px;
}

.bookmarkContainer {
  width: 20px;
  margin-right: 5px;
}

.bookmarkIconPinned,
.bookmarkIconUnPinned {
  margin-right: 16px;
  color: #1b3380;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
.respondentName {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #1b3380;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
}

.comment {
  margin-bottom: 8px;
  font-size: 16px;
}

.likeIcon {
  margin: 0 4px 0 8px;
}

.starIcon {
  height: 14px;
  width: 14px !important;
}
@media only screen and (max-width: 768px) {
  .container {
    min-width: 100%;
    margin: 4px 0;
    padding: 8px;
  }
  .comment {
    font-size: 14px;
  }
}
