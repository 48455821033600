
.footer {
    position: absolute;
    bottom: 0;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    border-top: 1px solid #d8d8d8;
}
.rightArrow {
    font-size: 20px;
    margin-left: -8px;
}