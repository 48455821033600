.container{
    padding: 16px;
    background: #fff;
    height: calc(100% - 162px);
    overflow: auto;
    min-height: calc(100% - 162px);
}
.waitingRoomText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.participantText{
    font-weight: 14px;
    font-weight: 500;
}
.participantContainer{
    display: flex;
    flex-wrap: wrap;
}
.respondent {
    padding: 4px 8px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    margin-right: 8px;
    margin-top: 8px;
}

.footer {
    position: absolute;
    bottom: 0;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    border-top: 1px solid #d8d8d8;
}
.rightArrow {
    font-size: 20px;
    margin-left: -8px;
}