.container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
}
.leftSide {
  display: flex;
  flex: 5;
  min-width: 200px;
  margin-right: 16px;
  align-items: center;
  margin-bottom: 8px;
}

.rightSide {
  font-size: 14px;
  flex: 5;
  display: flex;
  justify-content: space-between;
  min-width: 125px;
  margin-right: 8px;
}
.rightSideLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightSideRight {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftBox {
  height: 16px;
  width: 16px;
  background-color: var(--main, #1b3380);
  border-radius: 2px;
  margin-right: 8px;
}
.rightBox {
  margin-right: 8px;
  height: 16px;
  width: 16px;
  background-color: var(--mainLight, #8dc3f2);
  border-radius: 2px;
}
.rightSideForTeams {
  font-size: 16px;
  flex: 5;
  display: flex;
  justify-content: center;
  min-width: 125px;
  margin-right: 16px;
}
.secondRow {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .leftSide {
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
  }
  .rightSide {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    margin-right: 0;
  }
  .rightSideForTeams {
    display: none;
  }
  .secondRow {
    flex-grow: 1;
  }
  .teamsEnabledHeader {
    justify-content: flex-end;
  }
}
