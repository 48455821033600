.container {
    height: 100%;
    padding: 16px;
    background-color: #fff;
}
.questionText {
    font-size: 14px;
}
.innerContainer {
    margin-top: 16px;
    max-height: calc(100% - 160px);
    overflow: auto;
}
.word {
    background-color: #f5f5f5;
    padding: 8px;
    margin: 6px;
}
.wordText {
    font-weight: 500;
}
.emptyState {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}