.topScorerText {
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 8px;
}
.respondentList {
  display: flex;
  margin-bottom: 8px;
}
.respondentNameWithScore {
  margin-right: 16px;
  padding: 16px;
  background-color: #f5f5f5;
  color: #545e6b;
  line-height: 16px;
  font-weight: 400;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  position: relative;
  border-radius: 4px;
  font-size: 16px;
}
.topRespondent {
  composes: respondentNameWithScore;
  font-weight: 500;
  color: var(--main, #1b3380);
}
.ribbonImage {
  width: 12px;
  margin-right: 8px;
  height: 16px;
  display: flex;
  align-items: center;
}

.score {
  margin-left: 16px;
}

.respondentNameAndRibbon {
  display: flex;
}
@media screen and (max-width: 768px) {
  .topScorerText {
    font-size: 14px;
    margin-top: 16px;
  }
  .respondentList {
    margin-bottom: 4px;
    flex-direction: column;
  }
  .respondentNameWithScore {
    margin-bottom: 8px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .respondentName {
    margin-left: 0;
    font-size: 12px;
  }
  .respondentName:first-child {
    margin-left: 20px;
  }
}
