.container {
  display: flex;
  width: 100%;
}

.leftSide {
  width: 40%;
  display: flex;
  flex-direction: column;
  max-width: 170px;
  min-width: 120px;
  min-height: 115px;
  max-height: 120px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 8px;
  align-self: center;
}

.topTeamName {
  color: #1b3380;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  max-width: 152px;
  overflow: hidden;
}

.ribbonImg {
  width: 12px;
  height: 16px;
  margin-right: 4px;
}

.topTeamPieChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding-top: 8px;
}

.topTeamPercentageText {
  margin-left: 8px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-width: 50px;
}

.topTeamPercentageText > span {
  font-size: 16px;
}

.rightSide {
  margin-left: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

.leftSide,
.rightSide {
  box-sizing: border-box;
}
.teamNameWithPieChartRow {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teamName {
  max-width: 160px;
  min-width: 30px;
  margin-right: 8px;
}
.pieChartContainer {
  display: flex;
  align-items: center;
  min-width: 70px;
}

.borderTop {
  border-top: 1px solid #d8d8d8;
}

.percentageText {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .leftSide {
    display: none;
  }

  .rightSide {
    width: 100%;
    margin-left: 0;
    border-bottom: 1px solid #d8d8d8;
  }
  .borderTop {
    border: none;
  }
  .teamName {
    position: relative;
  }
  .teamName span {
    margin-left: 20px;
  }
  .boldTeamName {
    composes: teamName;
    font-weight: 500;
    color: var(--main, #1b3380)
  }
  .teamNameWithPieChartRow {
    padding: 6px 0;
  }
  .ribbonImgForSmallDevices {
    composes: ribbonImg;
    margin-right: 0;
    position: absolute;
    left: 0;
  }
}


