.timer {
  background-color: #333333;
  color: #78c6d5;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -59px;
  margin-bottom: 5px;
  width: 90px;
  float: right;
}

.minute {
  font-size: 25px;
}

.second {
  font-size: 20px;
}

.loadingSpinner {
  margin: auto;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingSpinner span {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: bold;
  color: #505050;
}

.pulsatingRing {
  content: '';
  border: 8px solid #f5f5f5;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: pulsate infinite 1s;
}

.pulsatingRing::before {
  content: '';
  border: 8px solid #f5f5f5;
  position: absolute;
  width: 90px;
  height: 90px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  border-radius: 50%;
  animation: pulsate infinite 1s;
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1.2, 1.2);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.6, 1.6);
    opacity: 0;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
