.container {
    height: 100%;
    padding: 16px;
    background-color: #fff;
}
.questionText {
    font-size: 14px;
    margin-bottom: 16px;
}

.emptyState{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}