.container {
    height: 75% !important;
    padding: 16px;
    background-color: #fff;
}
.questionText {
    font-size: 14px;
    margin-bottom: 16px;
}
.innerContainer {
    overflow: auto;
    height: calc(100% - 175px);
    margin-top: 16px;
    margin-bottom: 8px;
}
.comment {
    margin-top: 16px;
    background: #f5f5f5;
    padding: 8px;

}
.respName {
    margin-top: 4px;
}

.noComments{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}