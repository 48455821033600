.appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #e5e5e5;
  position: relative;
}

.navbarContainer {
  z-index: 3;
}

.mainContainer {
  position: relative;
  flex-grow: 1;
  overflow: auto;
}

.globalError {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.sessionExpiredTextWithButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sessionExpiredText {
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
