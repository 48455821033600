.container {
  min-height: 145px;
  overflow: hidden;
  max-width: 890px;
  height: 360px;
  margin-top: 16px;
  border: 1px solid #eee;
}
.smallContainer {
  composes: container;
  height: 145px;
  margin-top: 32px;
}
.noResponsesText {
  font-size: 16px;
  line-height: 24px;
  color: #545e6b;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 32px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 8px;
  }
  .smallContainer {
    margin-top: 16px;
  }
}
