.container {
  width: 100%;
  max-width: 935px;
}
.innerContainer {
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  flex-direction: column;
  max-height: calc(100vh - 430px);
  overflow: auto;
  margin: 16px 0;
  padding-right: 16px;
}

.innerContainerForReport {
  max-height: 100%;
  padding-right: 0;
  overflow: visible;
}

.row {
  display: flex;
  margin: 8px 0;
}
.leftSide {
  width: 50%;
  display: flex;
  min-width: 200px;
  margin-right: 16px;
  font-size: 14px;
  flex-direction: column;
}
.swipeOptionsLabels {
  display: flex;
  font-weight: 500;
}

.rightSide {
  width: 50%;
  display: flex;
  min-width: 150px;
  margin-right: 16px;
}
.bar {
  height: 8px;
  border-radius: 4px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .leftSide {
    width: 100%;
    margin-right: 16px;
  }
  .row {
    flex-direction: column;
  }
  .rightSide {
    width: 100%;
  }
}
