
.answers {
    margin-top: 16px;
}
.answer {    
    margin-top: 16px;
}

.bar {
    height: 8px;
    background: #1b87e6;
    border-radius: 4px;
    width: 0;
    margin-right: 8px;
    transition: width 0.5s ease;
}
.barContainer {
    display: flex;
    align-items: center;
}
.percentageRowContainer {
    display: flex;
    min-width: 60px;
    align-items: center;
}
.percentageText {
    width: 30px;
}

.correctIcon {
    font-size: 16px;
    color: #1b3380;
    margin-left: 8px;
}