.container{
    display: flex;   
    flex-direction: column;
}

.header {
     padding: 16px;
}
.livePollSessionName {
    font-size: 14px;
    font-weight: 500;
}
.pin {
    font-weight: 500;
    margin-top: 8px;
}
.pinRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

}