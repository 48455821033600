.container {
    min-height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.myPollsText {
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    width: 100%;
    text-align: left;
}
.card {
    border: 1px solid #ccc;
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
}
.cardInfo {
    display: flex;
    font-size: 12px;
    font-weight: 400;

}
.numberOfQuestions {
    margin-right: 8px;
}
.playCircleRow {
    display: flex;
    justify-content: center;
    align-items: center;
}
.playCircle {
    color: #1b3380;
    font-size: 24px;
    margin-left: 8px;
    cursor: pointer;
    visibility: hidden;
}
.card:hover .playCircle{
    visibility: visible;
}

.livePollTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85vw;
    width: 100%;
}