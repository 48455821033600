.mainContainer {
  display: flex;
}

.imageCardContainer {
  position: relative;
  min-width: 60px;
  width: 60px;
  min-height: 75px;
  height: 75px;
  border-radius: 4px;
  align-self: center;
}

.textCardContainer {
  min-width: 60px;
  width: 60px;
  min-height:75px;
  height: 75px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  align-self: center;
}

.redBorder {
  border: 4px solid var(--mainLight, #8DC3F2);
}

.greenBorder {
  border: 4px solid var(--main, #1b3380);
}

.textContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textCardRank {
  font-size: 16px;
  font-weight: 500;
}

.textBg {
  width: 30px;
  height: 24px;
  background-color: #fff;
  opacity: 0.8;
  border-bottom-right-radius: 4px;
}

.imageCardRank {
  position: absolute;
  font-size: 16px;
  font-weight: 500;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rightSideContainer {
  display: flex;
  flex-direction: column;
  margin: 4px 0 8px 16px;
  font-size: 14px;
}

.cardTitleContainer {
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.cardAnswerContainer {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.leftSwipeIconWithTextContainer {
  color: #ff7681;
}

.rightSwipeIconWithTextContainer {
  color: #1ba758;
}

.leftSwipeIconWithTextContainer,
.rightSwipeIconWithTextContainer {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.leftSwipeIconWithTextContainer > span:last-child,
.rightSwipeIconWithTextContainer > span:last-child {
  margin-left: 4px;
}

