.sortByContainer {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.icon {
  cursor: pointer;
  display: flex;
}
.row {
  display: flex;
}
.toggle {
  margin-right: 32px;
  height: 32px;
  width: 64px;
  border-radius: 16px;
  background: #e8e8e8;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.activeViewForResponse {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeViewForCard {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDown {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: #fff;
  margin: 0 24px 0 16px;
  min-width: 82px;
}
.dropDown:hover {
  background-color: #fff;
}
.dropDownItem {
  line-height: 32px;
  width: 100px;
  padding-left: 8px;
  cursor: pointer;
}
.dropDownItem:hover {
  background-color: #e8e8e8;
}
.dropDownContent {
  left: 8px;
}
