.socketDisconnectionMessage {
  font-size: 16px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refreshLink,
.refreshLink:hover {
  text-decoration: underline;
}
