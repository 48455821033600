.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 890px;
}
.header {
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #d8d8d8;
}
.headerRow {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.toggle {
  margin-right: 32px;
  height: 32px;
  width: 64px;
  border-radius: 16px;
  background: #e8e8e8;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.activeViewForResponse {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeViewForCard {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #1b87e6;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerContainer {
  width: 100%;
  height: 100%;
  max-height: 475px;
  overflow: auto;
  margin: auto;
  padding-right: 16px;
  box-sizing: content-box;
}

.innerContainerForReport {
  max-height: 100%;
}
.cardRow {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftSide {
  flex: 50;
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  margin-right: 16px;
}

.leftSideForHeader {
  composes: leftSide;
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 8px;
}
.sortByContainer {
  display: flex;
  align-items: center;
}

.rightSide {
  flex: 50;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 140px;
}
.ribbonImg {
  position: absolute;
  top: 4px;
  left: -30px;
}
.rightSideForHeader {
  composes: rightSide;
  justify-content: space-between;
  flex-direction: row;
}

.swipeOptionText {
  margin-bottom: 8px;
  font-size: 16px;
  color: #3fca7f;
  display: flex;
  align-items: center;
  position: relative;
}
.swipeOptionText:nth-child(1) {
  color: #ff7681;
}

.leftSideImage {
  margin-right: 8px;
}

.leftSideText,
.rightSideText {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rightSideImage {
  margin-left: 8px;
}

.leftAndRightTextRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.cardImage {
  height: 80px;
  width: 64px;
  position: relative;
}
.cardRank {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: rgba(255, 255, 255, 0.75);
  color: #545e6b;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardRankForEmptyCard {
  border-radius: 4px;
  position: absolute;
  color: #545e6b;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.firstRank {
  composes: cardRank;
  font-size: 14px;
  background: #3fca7f;
  color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 0 0 8px 0;
  align-items: baseline;
}
.firstRank span {
  font-size: 24px;
}
.firstRankForEmptyCard {
  composes: cardRankForEmptyCard;
  color: #fff !important;
}

.firstRankForEmptyCard span {
  font-size: 24px;
  margin-bottom: 4px;
}
.lastRankForEmptyCard {
  composes: cardRankForEmptyCard;
  color: #fff !important;
}
.lastRank {
  composes: cardRank;
  font-size: 14px;
  background: #ff7681;
  color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 0 0 8px 0;
}
.cardImagePic {
  border-radius: 4px;
  height: 80px;
  width: 64px;
  box-sizing: border-box;
  object-fit: cover;
}
.cardBorder {
  color: #fff !important;
  border: 4px solid #3fca7f !important;
}

.lastCardBorder {
  color: #fff !important;
  border: 4px solid #ff7681 !important;
}
.emptyCardImage {
  height: 80px;
  width: 64px;
  border: 2px solid #eee;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.cardBorderForEmptyCard {
  color: #fff !important;
  background-color: #3fca7f !important;
  border: 4px solid #a8e5bf !important;
}
.lastCardBorderForEmptyCard {
  color: #fff !important;
  background-color: #ff7681 !important;
  border: 4px solid #ffbac0 !important;
}

.cardText {
  min-width: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 16px;
  max-width: 320px;
  min-height: 80px;
}

.boldCardText {
  min-width: 120px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 16px;
  max-width: 320px;
  color: #1b3380;
  font-weight: 500;
  min-height: 80px;
}
.bar {
  position: relative;
  height: 16px;
  background: #eee;
  width: 100%;
  border-radius: 4px;
  display: flex;
  min-width: 120px;
}
.leftSideBorderRadius {
  border-radius: 4px 0 0 4px;
}

.rightSideBorderRadius {
  border-radius: 0 4px 4px 0;
}
.borderRadius {
  border-radius: 4px;
}
.icon {
  cursor: pointer;
}
.leftCheckMark {
  position: absolute;
  left: -35px;
  top: -8px;
}
.rightCheckMark {
  position: absolute;
  right: -35px;
  top: -8px;
}

.noDataAvailableMessage {
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  font-size: 16px;
}
.dropDown {
  border: 1px solid #d8d8d8;
  height: 32px;
  background-color: #fff;
  margin: 0 24px 0 16px;
  min-width: 95px;
}
.dropDown:hover {
  background-color: #fff;
}
.dropDownItem {
  line-height: 32px;
  width: 100px;
  padding-left: 8px;
  cursor: pointer;
}
.dropDownItem:hover {
  background-color: #e8e8e8;
}
.dropDownContent {
  left: 8px;
}
.dropdownText {
  width: 60px;
}

@media only screen and (max-width: 768px) {
  .headerRow {
    flex-direction: column;
  }
  .cardRow {
    flex-direction: column;
    align-items: flex-start;
  }
  .leftSide {
    flex: 100;
    width: 100%;
  }
  .rightSide {
    margin-top: 8px;
    flex: 100;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
  }
}
